import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
} from "react-icons/ai";
import { SiGravatar } from "react-icons/si";
import { FaTelegramPlane }  from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            Позвольте мне<span className="purple"> представить </span> себя
            </h1>
            <p className="home-about-body">
            Я влюбился в программирование и, по крайней мере, научился чему-то, я так думаю... 🤷‍♂️
              <br />
              <br />Я свободно владею такими классическими языками, как
              <i>
                <b className="purple"> Python и Javascript. </b>
              </i>
              <br />
              <br />
              Сфера моих интересов - создание новых &nbsp;
              <i>
                <b className="purple">Web Технологий и Продуктов </b>
              </i>
              <br />
              <br />
              По мере возможности я также применяю свою страсть к разработке продуктов с помощью <b className="purple">Node.js</b> и
              <i>
                <b className="purple">
                  {" "}
                  современных библиотек и фреймворков Javascript
                </b>
              </i>
              &nbsp; таких как
              <i>
                <b className="purple"> React и Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Ищите меня тут</h1>
            <p>
            Не стесняйтесь <span className="purple">связываться </span>со мной
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://t.me/stepansvift"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaTelegramPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://gravatar.com/stepansvift"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <SiGravatar />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/stsvift"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
