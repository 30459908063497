import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Привет всем, я <span className="purple">Степан Волков </span>
            из <span className="purple"> Москвы.</span>
            <br />
            Сейчас я обучаюсь в Колледже Связи №54 по специальности Информационные системы и программирование
            <br />
            <br />
            Помимо кодинга, есть и другие занятия, которыми я люблю заниматься!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Занимаюсь волейболом
            </li>
            <li className="about-activity">
              <ImPointRight /> Играю в игры
            </li>
            <li className="about-activity">
              <ImPointRight /> Увлекаюсь видео и фото
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Начинать всегда стоит с того, что сеет сомнения."{" "}
          </p>
          <footer className="blockquote-footer">Борис Стругацкий</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
